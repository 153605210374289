<template>
  <div v-if="loading" class="d-flex-center">
    <template v-if="!hideLoader">
      <fluency-loader />
      <small v-if="statusLog && statusLog.length > 0">
        {{statusLog[statusLog.length - 1]}}
      </small>
      <small v-else>Starting Processes</small>
    </template>
    <b-modal v-if="emailMethod"
             size="sm"
             v-model="showEmailModal"
             title="This is Taking a Long Time"
             cancel-title="I'll Wait"
             ok-title="Email Me"
             centered
             no-close-on-backdrop
             no-close-on-esc
             @ok="initEmail()"
             @cancel="illWait = true"
             @close="illWait = true">
      <p>If you don't want to wait, we can email the result to you when it's complete.</p>
    </b-modal>
  </div>
</template>

<script>
import md5 from 'md5'
import FluencyLoader from 'core-ui/components/common/fluencyLoader.vue'

export default {
  name: 'asyncFetcher',
  components: { FluencyLoader },
  props: {
    timeout: { // 'Time in milliseconds'
      type: Number,
      default: 1000
    },
    timesToAttempt: { // Number of times to try before failing
      type: Number,
      default: 120
    },
    diffTimeout: { // How long to tolerate no updates before failing
      type: Number,
      default: 60000
    },
    payload: { // JSON object received from a Fluency API call
      type: Object,
      default: null
    },
    hideLoader: {
      type: Boolean,
      default: false
    },
    file: {
      type: Boolean,
      default: false
    },
    emailMethod: {
      type: Function
    },
    emailSeconds: {
      type: Number,
      default: 30
    }
  },
  render: function (createElement) {
    return createElement('div', { class: 'd-none' })
  },
  data () {
    return {
      logging: true,
      attempts: null,
      attemptsTotal: null,
      lastHash: null,
      timeSinceLastChange: 0,
      timePerformanceStart: 0,
      timeTotalElapsed: 0,
      payload2: null,
      statusLog: [],
      loading: false,
      callback: undefined,
      showEmailModal: false,
      illWait: false
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
    this.killAllPollers(this.asyncResponse)
  },
  watch: {
    asyncResponse: {
      handler (newVal, oldVal) {
        if (newVal !== null) {
          if (oldVal !== null) {
            this.killAllPollers(oldVal)
            this.end()
          }

          this.createPoller()
        }
      },
      deep: true
    },
    attemptsTotal: {
      handler (val) {
        if (val !== null) {
          this.checkPollingEndpoint()
        }
      }
    },
    timeTotalElapsed (value) {
      const s = this.$route.query.emailSeconds ? parseInt(this.$route.query.emailSeconds) : this.emailSeconds
      if (this.emailMethod && !this.illWait && value >= (s * 1000)) {
        this.showEmailModal = true
      }
    }
  },
  computed: {
    asyncResponse () {
      return this.payload || this.payload2
    }
  },
  methods: {
    async asyncFetch (method, args, callback) {
      // can be used by parent
      this.loading = true
      if (callback) {
        this.callback = callback
      }
      this.$emit('start')
      const resp = await method.apply(this, args)
      if (resp) {
        this.payload2 = resp
      } else {
        this.end()
        this.fail('initial call', 'initial call failed')
      }
    },
    log (msg, color = 'hotpink', msg2 = '') {
      if (this.logging) {
        console.log(`%c===== ${msg} %c${msg2}`, `font-weight:bold;color:${color};`, 'font-weight:normal;color:black;')
      }
    },
    init () {
      this.log('RESET ASYNC POLLER', 'cornflowerblue')
      this.$setCompat(this, 'attempts', null)
      this.$setCompat(this, 'attemptsTotal', null)
    },
    start () {
      this.$setCompat(this, 'timePerformanceStart', Math.floor(performance.now()))
      this.$setCompat(this, 'attempts', 0)
      this.$setCompat(this, 'attemptsTotal', 0)
      if (!this.loading) {
        this.$emit('start')
      }
      this.loading = true
    },
    end () {
      this.$emit('end')
      this.showEmailModal = false
      this.illWait = false
      this.loading = false
      this.statusLog = []
      this.payload2 = null
      this.callback = undefined
    },
    status (o) {
      this.statusLog = o?.status
      this.$emit('status', { content: o?.status, length: o?.totalMessageSize || 0 })
    },
    fail (type, msg) {
      this.log(`FAILED: ${type} ${msg}`, 'orangered')
      this.$emit('fail', { type, msg })
      if (this.callback) {
        this.callback(false)
      }
    },
    async success (result) {
      let eventData = result
      if (this.file) {
        eventData = await this.$res.fetch.asyncFetchFileResource(result)
        if (!eventData) {
          this.fail('file fetch', 'failed to fetch file after polling had finished')
          return
        }
      }
      this.$emit('success', eventData)
      if (this.callback) {
        this.callback(eventData)
      }
    },
    createPoller () {
      this.log(`New Poller: every ${this.timeout}ms up to ${this.timesToAttempt} times while changes are taking place.`, 'limegreen')
      this.start()
    },
    async initEmail () {
      const res = await this.emailMethod(this.asyncResponse)
      if (res) {
        await this.success('Delegated to Email')
        this.end()
        this.$toast('Email Service Initiated', 'success')
      }
    },
    async checkPollingEndpoint () {
      if (!this.asyncResponse) {
        this.attempts = null
        this.attemptsTotal = null
        return
      }

      this.log(`Poll Attempt #${this.attempts}`, 'cornflowerblue', `(${this.attemptsTotal + 1} total)`)

      const response = await this.$res.fetch.asyncGet(this.asyncResponse)

      const responseHash = (response) ? md5(JSON.stringify(response)) : null

      if (responseHash === null) {
        console.warn('Invalid response in AsyncFetcher - async/get')
        this.$bugsnag(new Error('Invalid response from async/get'), {
          metadata: {
            response,
            payload: this.asyncResponse
          }
        })
      }

      // VALID RESPONSE
      if (response?.complete) {
        this.log('DONE!', 'limegreen', `(${this.timeTotalElapsed / 1000} seconds elapsed)`)
        this.init()
        await this.success(response.result)
        this.end()
        return true
      }

      // ERROR FOR ORPHANED JOB
      if (response?.found === false) {
        this.log('POLLING OBJECT NOT FOUND!', 'orangered')
        this.fail('ORPHANED_JOB', 'Polling object not found. Aborting.')
        this.end()
        return false
      }

      // TIMED OUT AFTER `pollingAttempts` TRIES
      if (this.attempts >= this.timesToAttempt) {
        this.log('EXCEEDED ALLOWED ATTEMPTS!', 'orangered')
        this.fail('EXCEEDED_ALLOWED_ATTEMPTS', `Attempted to make ${this.timesToAttempt} calls without detecting a change. Aborting.`)
        this.end()
        return false
      }

      // LOGIC TO CHECK FOR DIFFS (IE - STALLED JOBS)
      if (this.lastHash && this.lastHash === responseHash) {
        this.incrementHashDiffTimeout()
      } else {
        this.clearHashDiffTimeout()
        this.$setCompat(this, 'attempts', 0)
      }
      if (this.timeSinceLastChange >= this.diffTimeout) {
        this.log('NO CHANGES!', 'orangered')
        this.fail('STALLED_JOB', `No changes detected for ${this.diffTimeout / 1000} seconds. Aborting.`)
        // this.killAllPollers()
        this.end()
        return false
      }

      if (response?.status && response?.status?.length > 0) {
        this.log('Status Emitted')
        this.status(response)
      } else {
        this.log('Status n/a')
      }

      this.setHash(responseHash)

      setTimeout((v) => {
        v.incrementAttempts()
      }, this.timeout, this)
    },
    incrementHashDiffTimeout () {
      this.$setCompat(this, 'timeSinceLastChange', this.timeSinceLastChange + this.timeout)
      this.trackTotalTime()
    },
    clearHashDiffTimeout () {
      this.$setCompat(this, 'timeSinceLastChange', 0)
      this.trackTotalTime()
    },
    trackTotalTime () {
      const tNow = Math.ceil(performance.now())
      this.$setCompat(this, 'timeTotalElapsed', tNow - this.timePerformanceStart)
    },
    setHash (hash) {
      this.$setCompat(this, 'lastHash', hash)
    },
    incrementAttempts () {
      if (this.attempts !== null) {
        this.$setCompat(this, 'attempts', this.attempts + 1)
        this.$setCompat(this, 'attemptsTotal', this.attemptsTotal + 1)
      }
    },
    async killAllPollers (payload = null) {
      if (payload) {
        // Tell API it's safe to kill a running job by `payload`
        await this.$res.fetch.asyncKill(payload)
        this.log('KILL EXISTING POLLER:', 'orangered')
        this.log(JSON.stringify(payload), 'orangered')
      }

      this.$emit('kill')
      this.init()
    }
  }
}
</script>
